import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import VideoComponent from '@components/common/VideoComponent/VideoComponent';
import BaseFeatureCard from '@components/features-card/BaseFeatureCard';
import Description from '@components/features-card/Description';
import Title from '@components/features-card/Title';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { getAppropriateUrl } from '@invideoio/web-shared/utils/common';
import {
  AmplitudeEvents,
  sendAmplitudeAnalyticEvent,
  sendRudderStackAnalyticEvent,
} from '@utils/analytics';
import { useContext } from 'react';
import { Card1Props } from 'src/types';
import {
  AnalyticsProductType,
  ClickCtaType,
  ClickCtaUxSource,
} from 'src/types/analytics.types';
import { AUTH_TYPE } from '../../constants/homepage';
import { RedirectContext, StateContext } from '../../store/store';
import { BaseAppType } from '@constants/common';

const Card1 = (props: Card1Props) => {
  const {
    heading,
    image,
    text,
    btnText,
    CTAUrl,
    DarkText,
    layoutV3,
    platform,
    browser,
  } = props;
  const { url, alternativeText: alt } = image;
  const { handleClick } = useContext(RedirectContext);
  const { baseAppType } = useContext(StateContext);
  const onClick = () => {
    sendAmplitudeAnalyticEvent(AmplitudeEvents.exploreTemplates, {
      'Button Position': 'Features Section',
    });
    sendRudderStackAnalyticEvent({
      event_name: 'click_cta',
      event_properties: {
        ux_source: ClickCtaUxSource.FeaturesSelection,
        type: ClickCtaType.ClickSignup,
        feature_utm: 'na',
        product:
          baseAppType === BaseAppType.AI
            ? AnalyticsProductType.AI
            : AnalyticsProductType.STUDIO,
      },
    });
    handleClick(CTAUrl, { tab: AUTH_TYPE.SIGNUP });
  };

  const imageURL = getAppropriateUrl(url, browser, platform);

  return (
    <BaseFeatureCard
      layoutV3={layoutV3}
      heading={
        <Title
          headingColor={
            typeof DarkText === 'boolean' && !DarkText
              ? 'iv-text-grey-0'
              : 'iv-text-grey-85'
          }
          heading={heading}
          heading2Class={ivclass('md:iv-inline', layoutV3 && 'sm:iv-inline')}
        />
      }
      cardClasses={ivclass('iv-justify-between')}
      media={
        <div
          className={ivclass(
            'iv-flex-grow iv-relative',
            image.mime.includes('video')
              ? 'sm:iv-h-[150px] sm:iv-mb-45'
              : 'sm:iv-mb-10',
          )}
        >
          <div
            className={ivclass(
              'iv-absolute',
              image.mime.includes('video')
                ? 'xl:iv-top-[-25px] iv-top-[-13px] md:iv-top-[-7px] sm:iv-top-[15px]'
                : 'xl:iv-top-[-25px] iv-top-[-13px] md:iv-top-[-7px]',
              'iv-h-full iv-w-full',
            )}
          >
            {image.mime.includes('video') ? (
              <VideoComponent
                className={ivclass(
                  'iv-object-cover iv-h-full iv-w-full',
                  'lazyload',
                )}
                autoPlay
                muted
                playsInline
                preload="auto"
                loop
              >
                <source data-src={image?.url} type="video/mp4" />
              </VideoComponent>
            ) : (
              <ImageComponent
                elementype="native"
                src={imageURL}
                alt={alt}
                className="iv-object-contain iv-absolute iv-inset-0 iv-w-full iv-h-full"
                loading="lazy"
              />
            )}
          </div>
        </div>
      }
      description={<Description description={text} />}
      CTA={
        <button
          className={ivclass(
            'iv-inline-flex iv-justify-center iv-items-center iv-cursor-pointer',
            'iv-rounded-full',
            'iv-border-2 iv-border-grey-85',
            'iv-px-15',
            'iv-h-32',
            'iv-mt-25',
            !layoutV3 && 'sm:iv-hidden',
            'iv-text-body-xl',
            'iv-m-body-xl',
            'iv-text-grey-85',
            'iv-font-semibold',
            'iv-transition hover:iv-bg-grey-85 hover:iv-text-grey-0',
          )}
          type="button"
          onClick={onClick}
        >
          {btnText}
        </button>
      }
    />
  );
};

export default Card1;
